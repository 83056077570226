import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { listAttributes } from '../actions/attributeActions';
import { addToCart, removeFromCart, savePaymentMethod, updateQty } from '../actions/cartActions';
import { getNotificationsUser } from '../actions/notificationActions';
import { createOrder, detailsOrder, editOrder } from '../actions/orderActions';
import { listProducts } from '../actions/productActions';
import { ToolBar } from '../components/ToolBar';
import { ORDER_CREATE_RESET, ORDER_EDIT_RESET } from '../constants/orderConstants';
import { getPayCometData } from '../actions/paycometDataActions';

export const CartScreen = (props) => {

    const params = useParams();

    const productId = params.id;

    const navigate = useNavigate();

    const [searchParms] = useSearchParams();
    const qty = Number(searchParms.get("qty"));


    const cart = useSelector(state => state.cart);
    const { cartItems } = cart;

    const attributeList = useSelector((state) => state.attributeList);
    const { attributes } = attributeList;

    const productList = useSelector((state) => state.productList);
    const { products } = productList;

    const orderCreate = useSelector((state) => state.orderCreate);
    const {success} = orderCreate;

    const orderEdit = useSelector((state) => state.orderEdit)

    const orderDetails = useSelector(state => state.orderDetails);

    const {paycometData} = useSelector(state => state.paycometGetData)

    const [redirect, setRedirect] = useState(false)
    const [clickCreate, setClickCreate] = useState(false)

    const dispatch = useDispatch();

    const updateOrder = () =>  {
        if (orderDetails?.order) {
            if (cart.cartItems !== orderDetails.order.orderItems) {
                const toPrice = (num) => Number(num.toFixed(2)); // 5.123 => "5.12" => 5.12

                let totalPrice = 0
                let totalTaxes = 0

                cart.cartItems.forEach(item => {
                    let priceWithoutTaxes = item.price / (1 + item.iva / 100)
                    let taxesPrice = item.price - priceWithoutTaxes

                    totalPrice += item.qty * priceWithoutTaxes
                    totalTaxes += item.qty * taxesPrice
                });

                // Precio total de los artículos
                cart.itemsPrice = toPrice(totalPrice)
                //Precio total del iva de los artículos
                cart.taxPrice = toPrice(totalTaxes)
                // Coste de envío
                cart.shippingPrice = 0;
                // Total: precio de los articulos + coste de envío + impuestos
                cart.totalPrice = cart.itemsPrice + cart.shippingPrice + cart.taxPrice;
                dispatch(editOrder(localStorage.getItem('orderId'), {...cart, orderItems: cart.cartItems}))
            }
        }
    }

    useEffect(() => {
        if (productId) {
            dispatch(addToCart(productId, qty))
        }
    }, [dispatch, productId, qty])

    useEffect(() => {
        dispatch(listAttributes())
        dispatch(listProducts());
        dispatch(getNotificationsUser());
        dispatch(getPayCometData())
    }, [dispatch])

    useEffect(() => {
        if (cart.paymentMethod && localStorage.getItem('orderId') === null) {
            const toPrice = (num) => Number(num.toFixed(2)); // 5.123 => "5.12" => 5.12
  
            let totalPrice = 0
            let totalTaxes = 0

            cart.cartItems.forEach(item => {
                let priceWithoutTaxes = item.price / (1 + item.iva / 100)
                let taxesPrice = item.price - priceWithoutTaxes

                totalPrice += item.qty * priceWithoutTaxes
                totalTaxes += item.qty * taxesPrice
            });

            // Precio total de los artículos
            cart.itemsPrice = toPrice(totalPrice)
            //Precio total del iva de los artículos
            cart.taxPrice = toPrice(totalTaxes)
            // Coste de envío
            cart.shippingPrice = 0;
            // Total: precio de los articulos + coste de envío + impuestos
            cart.totalPrice = cart.itemsPrice + cart.shippingPrice + cart.taxPrice;
            dispatch(createOrder({...cart, orderItems: cart.cartItems}))
            setClickCreate(true)
            // cart.cartItems.forEach(item => {
            //     let fields = {attr: false, stock: item.qty, value: null}
            //     if (item.attributes.length > 0) {
            //         fields.attr = true
            //         fields.value = item.attributes[0].value
            //     }
            //     dispatch(updateStock(fields, item.product))
            // });
        }
    }, [cart, dispatch])

    useEffect(() => {
        if(success === true && clickCreate) {
            dispatch({type: ORDER_CREATE_RESET});
            setRedirect(true)
            setClickCreate(false)
        }
      }, [dispatch, success, clickCreate])

      useEffect(() => {
          if (orderEdit.success === true) {
              dispatch({type: ORDER_EDIT_RESET})
          }
      }, [orderEdit, dispatch])

      useEffect(() => {
        if (redirect === true && !success && !orderEdit.success) {
            setRedirect(false)
            dispatch({type: ORDER_EDIT_RESET})
            dispatch({type: ORDER_CREATE_RESET});
            navigate(`/order/${localStorage.getItem('orderId')}`);
        }
      }, [navigate, redirect, dispatch, success, orderEdit])

    const removeFromCartHandler  = (index) => {
        dispatch(removeFromCart(index))
    }


    const checkoutHandler = () => {
        if (localStorage.getItem('orderId') === null) {
            dispatch(savePaymentMethod('Tarjeta crédito/débito'));
        } else {
            updateOrder()
            setRedirect(true)
        }
    }

    return (
        <>

            <ToolBar titulo={"Mi cesta"} hitos={[
                { titulo: "Inicio", enlace: "/" },
                { titulo: "Carrito", enlace: "#" }]} />
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="content flex-row-fluid" id="kt_content">
                    <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
                        <div className="col-xl-8">
                            <div className="card card-flush">
                                <div className="card-body">
                                    <div className="col-12 fs-6 gy-5">
                                        {
                                            cartItems?.length > 0
                                                ?
                                                    <>
                                                        <div className='d-md-flex d-none row fw-bolder text-start text-gray-400 fs-7 text-uppercase gs-0 border-bottom pb-5'>
                                                            <div className='col-6'>Producto</div>
                                                            <div className='col-6'>
                                                                <div className='row justify-content-end'>
                                                                    <div className='col-3'>Precio</div>
                                                                    <div className='col-3'>Cantidad</div>
                                                                    <div className='col-2'></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            cartItems?.map((item, index) => (
                                                                <div className='row align-items-center border-bottom pb-3' key={index}>
                                                                    <div className='col-12 col-md-6'>
                                                                        <div className="d-flex align-items-center flex-row py-3">
                                                                            <Link to={`/product/${item.product}`} className="symbol cart-product-image">
                                                                                <span className="symbol-label" style={{ backgroundImage: `url(${item.image})` }}></span>
                                                                            </Link>
                                                                            <div className="ms-5">
                                                                                <Link to={`/product/${item.product}`} className="text-gray-800 text-hover-primary fs-5 fw-bolder" data-kt-ecommerce-product-filter="product_name">
                                                                                    {item.name}
                                                                                    {item.attributes?.length > 0
                                                                                        ?
                                                                                        `, ${attributes?.find(att => att._id === item.attributes[0].attribute)?.name}: ${item.attributes[0].value}`
                                                                                        :
                                                                                        ''}
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-12 col-md-6'>
                                                                        <div className='row align-items-center justify-content-end'>
                                                                            <div className='col-3'>
                                                                                {item.price} €
                                                                            </div>
                                                                            <div className='col-3'>
                                                                                <select className='fw-bolder' value={item.qty} onChange={e => dispatch(updateQty(item.product, Number(e.target.value)))}>
                                                                                    {
                                                                                        products?.find(prod => prod._id === item.product)?.countInStock
                                                                                            ?
                                                                                                products?.find(prod => prod._id === item.product)?.maxUnits
                                                                                                ?
                                                                                                    [...Array(products?.find(prod => prod._id === item.product)?.countInStock > products?.find(prod => prod._id === item.product)?.maxUnits ? products?.find(prod => prod._id === item.product)?.maxUnits : products?.find(prod => prod._id === item.product)?.countInStock).keys()].map((x) => (
                                                                                                        <option key={x + 1} value={x + 1}>{x + 1}</option>
                                                                                                    ))
                                                                                                :
                                                                                                    [...Array(products?.find(prod => prod._id === item.product)?.countInStock > 100 ? 100 : products?.find(prod => prod._id === item.product)?.countInStock).keys()].map((x) => (
                                                                                                        <option key={x + 1} value={x + 1}>{x + 1}</option>
                                                                                                    ))
                                                                                            :
                                                                                                products?.find(prod => prod._id === item.product)?.attributes.find(attr => attr.value.value === item.attributes[0]?.value)?.maxUnits
                                                                                                ?
                                                                                                    [...Array(products?.find(prod => prod._id === item.product)?.attributes.find(attr => attr.value.value === item.attributes[0]?.value)?.stock > products?.find(prod => prod._id === item.product)?.attributes.find(attr => attr.value.value === item.attributes[0]?.value)?.maxUnits ? products?.find(prod => prod._id === item.product)?.attributes.find(attr => attr.value.value === item.attributes[0]?.value)?.maxUnits : products?.find(prod => prod._id === item.product)?.attributes.find(attr => attr.value.value === item.attributes[0]?.value)?.stock).keys()].map((x) => (
                                                                                                        <option key={x + 1} value={x + 1}>{x + 1}</option>
                                                                                                    ))
                                                                                                :
                                                                                                    [...Array(products?.find(prod => prod._id === item.product)?.attributes.find(attr => attr.value.value === item.attributes[0]?.value)?.stock > 100 ? 100 : products?.find(prod => prod._id === item.product)?.attributes.find(attr => attr.value.value === item.attributes[0]?.value)?.stock).keys()].map((x) => (
                                                                                                        <option key={x + 1} value={x + 1}>{x + 1}</option>
                                                                                                    ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                            <div className='col-2'>
                                                                                <i className="bi bi-trash-fill" onClick={() => removeFromCartHandler(index)}></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </>
                                                :
                                                    <span className='text-muted fs-3 fw-bolder'>
                                                        El carrito está vacío
                                                    </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="card h-md-100">
                                <div className="card-body d-flex flex-column">
                                    <h2>
                                        Subtotal ({cartItems.reduce((a, c) => parseInt(a) + parseInt(c.qty), 0)} artículos) : <span>{cartItems.reduce((a, c) => parseFloat(a) + parseFloat(c.price) * c.qty, 0).toFixed(2)} €</span>
                                    </h2>
                                    {
                                        // TODO: Calcular comisiones
                                    }
                                    <button type="button" onClick={checkoutHandler} className="btn btn-primary btn-block" disabled={cartItems.length === 0}>Finalizar compra</button>
                                    <Link to="/" className='btn btn-primary mt-5'>Seguir comprando</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}