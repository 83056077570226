export const calculateCommission = (commission, price) => {
    const commissionData = {
        commission: 0,
        total: parseFloat(price)
    }

    commissionData.commission = ((price * commission) / 100)
    commissionData.total += commissionData.commission

    return commissionData
}